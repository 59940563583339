import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  AppBar,
  Toolbar,
  Switch,
  FormControlLabel,
  Card,
  CardContent,
  Pagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SchoolIcon from '@mui/icons-material/School';
import axios from 'axios';
import './App.css';

function App() {
  const [startTime, setStartTime] = useState(localStorage.getItem('startTime') || '');
  const [isTracking, setIsTracking] = useState(!!localStorage.getItem('startTime'));
  const [workDuration, setWorkDuration] = useState('00:00:00');
  const [balance, setBalance] = useState(0);
  const [schoolday, setSchoolday] = useState(false);
  const [logs, setLogs] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [isPaused, setIsPaused] = useState(localStorage.getItem('isPaused') === 'true');
  const [pauseStartTime, setPauseStartTime] = useState(
    localStorage.getItem('pauseStartTime') ? parseInt(localStorage.getItem('pauseStartTime')) : null
  );
  const [pauseTimeLeft, setPauseTimeLeft] = useState(0);
  const [workedSeconds, setWorkedSeconds] = useState(0);
  const [pauseCompleted, setPauseCompleted] = useState(
    localStorage.getItem('pauseCompleted') === 'true'
  );
  const [startTimestamp, setStartTimestamp] = useState(
    localStorage.getItem('startTimestamp') ? parseInt(localStorage.getItem('startTimestamp')) : null
  );
  const [currentTime, setCurrentTime] = useState(new Date());

  // Zusätzliche States für Pagination und Suche
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 10;

  const fetchLogs = () => {
    axios
      .get('https://time.nohello.biz/api/logs')
      .then((response) => {
        setLogs(response.data);
        const balanceSum = response.data.reduce((sum, log) => sum + parseFloat(log.balance), 0);
        setTotalBalance(balanceSum);
      })
      .catch((error) => console.error('Fehler beim Abrufen der Logs:', error));
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timeInterval);
  }, []);

  const updateWorkedSeconds = () => {
    if (startTimestamp) {
      const now = Date.now();
      const totalWorkedSeconds = Math.floor((now - startTimestamp) / 1000);

      let actualWorkedSeconds = totalWorkedSeconds;

      if (isPaused && pauseStartTime) {
        actualWorkedSeconds = Math.floor((pauseStartTime - startTimestamp) / 1000);
      } else if (pauseCompleted) {
        actualWorkedSeconds = totalWorkedSeconds - 30 * 60;
      }

      setWorkedSeconds(actualWorkedSeconds);

      const hrs = Math.floor(actualWorkedSeconds / 3600)
        .toString()
        .padStart(2, '0');
      const mins = Math.floor((actualWorkedSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
      const secs = (actualWorkedSeconds % 60).toString().padStart(2, '0');

      setWorkDuration(`${hrs}:${mins}:${secs}`);

      const fixedHours = 7.8;
      const workedHours = actualWorkedSeconds / 3600;
      const currentBalance = workedHours - fixedHours;

      setBalance(currentBalance);
    }
  };

  useEffect(() => {
    let interval = null;

    if (isTracking && startTime) {
      const start = startTimestamp ? new Date(startTimestamp) : new Date();

      if (!startTimestamp) {
        const [hours, minutes] = startTime.split(':');
        start.setHours(hours);
        start.setMinutes(minutes);
        start.setSeconds(0);
        start.setMilliseconds(0);

        const timestamp = start.getTime();
        setStartTimestamp(timestamp);
        localStorage.setItem('startTimestamp', timestamp.toString());
      }

      updateWorkedSeconds();

      interval = setInterval(() => {
        const now = Date.now();

        const totalWorkedSeconds = Math.floor((now - start.getTime()) / 1000);

        if (!isPaused && !pauseCompleted && totalWorkedSeconds >= 6 * 3600) {
          const pauseStart = start.getTime() + 6 * 3600 * 1000;
          setIsPaused(true);
          setPauseStartTime(pauseStart);
          localStorage.setItem('isPaused', 'true');
          localStorage.setItem('pauseStartTime', pauseStart.toString());

          setWorkedSeconds(6 * 3600);
        }

        if (isPaused) {
          const pauseElapsedSeconds = Math.floor((now - pauseStartTime) / 1000);

          if (pauseElapsedSeconds >= 30 * 60) {
            setIsPaused(false);
            setPauseTimeLeft(0);
            setPauseCompleted(true);
            localStorage.setItem('isPaused', 'false');
            localStorage.setItem('pauseCompleted', 'true');
          } else {
            setPauseTimeLeft(30 * 60 - pauseElapsedSeconds);
          }
        }

        updateWorkedSeconds();
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isTracking, startTime, isPaused, pauseStartTime, startTimestamp, pauseCompleted]);

  const handleStart = () => {
    if (startTime) {
      localStorage.setItem('startTime', startTime);
      setIsTracking(true);

      const start = new Date();
      const [hours, minutes] = startTime.split(':');
      start.setHours(hours);
      start.setMinutes(minutes);
      start.setSeconds(0);
      start.setMilliseconds(0);

      const timestamp = start.getTime();
      setStartTimestamp(timestamp);
      localStorage.setItem('startTimestamp', timestamp.toString());
    }
  };

  const handleReset = () => {
    setStartTime('');
    setIsTracking(false);
    setWorkDuration('00:00:00');
    setBalance(0);
    setIsPaused(false);
    setPauseStartTime(null);
    setPauseTimeLeft(0);
    setWorkedSeconds(0);
    setStartTimestamp(null);
    setPauseCompleted(false);
    localStorage.removeItem('startTime');
    localStorage.removeItem('isPaused');
    localStorage.removeItem('pauseStartTime');
    localStorage.removeItem('startTimestamp');
    localStorage.removeItem('pauseCompleted');
  };

  const handleCheckout = () => {
    const endTime = new Date().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
    const newLog = {
      date: new Date().toISOString().split('T')[0],
      start_time: startTime,
      end_time: endTime,
      duration: workDuration,
      balance: parseFloat(balance), // Sicherstellen, dass balance als Zahl gespeichert wird
      schoolday: schoolday,
    };

    axios
      .post('https://time.nohello.biz/api/logs', newLog)
      .then(() => {
        fetchLogs();
        handleReset();
      })
      .catch((error) => console.error('Fehler beim Speichern des Logs:', error));
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://time.nohello.biz/api/logs/${id}`)
      .then(() => fetchLogs())
      .catch((error) => console.error('Fehler beim Löschen des Logs:', error));
  };

  const formatLogDate = (dateString) => {
    const date = new Date(dateString);
    const days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    const dayName = days[date.getDay()];
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${dayName} (${day}.${month})`;
  };

  const formatTime = (timeString) => {
    return timeString ? timeString.slice(0, 5) : ''; // Nimmt nur die ersten fünf Zeichen (HH:MM)
  };

  const formatPauseTimeLeft = (pauseTimeLeftInSeconds) => {
    const minutes = Math.floor(pauseTimeLeftInSeconds / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (pauseTimeLeftInSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const formatCurrentTime = (date) => {
    return date.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formatBalance = (balance) => {
    return `${balance >= 0 ? '+' : ''}${balance.toFixed(2)}`;
  };

  // Suchfunktion: Filter basierend auf Datum, Stundenanzahl oder Tag
  const filteredLogs = useMemo(() => {
    if (!searchQuery) return logs;
    return logs.filter((log) => {
      const dateMatch = log.date.includes(searchQuery);
      const durationMatch = log.duration.includes(searchQuery);
      const dayMatch = formatLogDate(log.date).toLowerCase().includes(searchQuery.toLowerCase());
      return dateMatch || durationMatch || dayMatch;
    });
  }, [logs, searchQuery]);

  // Pagination: Berechnung der aktuellen Logs
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);
  const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Zurücksetzen auf die erste Seite bei neuer Suche
  };

  return (
    <Box
      className="App"
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      sx={{ paddingTop: 65, paddingBottom: 10 }}
    >
      {/* Benutzer-, Stundenkonto- und Uhrzeit-Panels */}
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
                borderRadius: 2,
                height: '150px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              elevation={3}
            >
              <Typography variant="h6">Benutzer</Typography>
              <Typography variant="h4">Jannik Blankenburg</Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                textAlign: 'center',
                backgroundColor: totalBalance >= 0 ? '#e8f5e9' : '#ffebee',
                borderRadius: 2,
                height: '150px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              elevation={3}
            >
              <Typography variant="h6">Stundenkonto</Typography>
              <Typography variant="h4" sx={{ color: totalBalance >= 0 ? 'green' : 'red' }}>
                {totalBalance >= 0
                  ? `+${totalBalance.toFixed(2)} Stunden`
                  : `${totalBalance.toFixed(2)} Stunden`}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                p: 2,
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
                borderRadius: 2,
                height: '150px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              elevation={3}
            >
              <Typography variant="h6">Aktuelle Uhrzeit</Typography>
              <Typography variant="h4">{formatCurrentTime(currentTime)}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* AppBar */}
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div">
            Arbeitszeit Tracker Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Timer und Logs */}
      <Container maxWidth="lg" sx={{ mt: 4, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Paper sx={{ p: 4, maxWidth: 600, width: '100%' }}>
                <Typography variant="h5" gutterBottom>
                  Timer
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    type="time"
                    label="Startzeit (HH:MM)"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    fullWidth
                    required
                    disabled={isTracking}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={schoolday}
                      onChange={(e) => setSchoolday(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Schultag"
                />
                <Box sx={{ mt: 2 }}>
                  {!isTracking ? (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleStart}
                      disabled={!startTime}
                    >
                      Start
                    </Button>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button variant="outlined" color="secondary" fullWidth onClick={handleReset}>
                          Reset
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" color="success" fullWidth onClick={handleCheckout}>
                          Checkout
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                {isTracking && (
                  <Card sx={{ mt: 4, backgroundColor: '#f5f5f5' }}>
                    <CardContent>
                      {isPaused ? (
                        <>
                          <Typography variant="h6" align="center" color="textSecondary">
                            Noch {formatPauseTimeLeft(pauseTimeLeft)} Minuten Pause
                          </Typography>
                          <Typography variant="h6" align="center" color="textSecondary">
                            Arbeitszeit
                          </Typography>
                          <Typography variant="h3" align="center" color="textSecondary">
                            {workDuration}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="h6" align="center">
                            Arbeitszeit
                          </Typography>
                          <Typography variant="h3" align="center">
                            {workDuration}
                          </Typography>
                          <Typography
                            variant="h6"
                            align="center"
                            sx={{
                              color: balance < 0 ? 'red' : 'green',
                              mt: 2,
                            }}
                          >
                            {balance < 0
                              ? `Minusstunden: -${Math.abs(balance).toFixed(2)}`
                              : `Plustunden: +${balance.toFixed(2)}`}{' '}
                            Stunden
                          </Typography>
                        </>
                      )}
                    </CardContent>
                  </Card>
                )}
              </Paper>
            </Box>
          </Grid>

          {/* Suchleiste und Logs-Tabelle */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, flexGrow: 1, overflow: 'hidden' }}>
              <Typography variant="h5" gutterBottom>
                Alle Logs
              </Typography>

              {/* Suchleiste */}
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Suche nach Datum, Dauer oder Tag"
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Box>

              {/* Logs-Tabelle */}
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Datum</TableCell>
                      <TableCell>Start</TableCell>
                      <TableCell>Ende</TableCell>
                      <TableCell>Dauer</TableCell>
                      <TableCell>Saldo</TableCell>
                      <TableCell>Schule</TableCell>
                      <TableCell>Löschen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentLogs.map((log) => (
                      <TableRow key={log.id}>
                        <TableCell>{formatLogDate(log.date)}</TableCell>
                        <TableCell>{formatTime(log.start_time)}</TableCell>
                        <TableCell>{formatTime(log.end_time)}</TableCell>
                        <TableCell>{log.duration}</TableCell>
                        <TableCell sx={{ color: log.balance >= 0 ? 'green' : 'red' }}>
                          {formatBalance(parseFloat(log.balance))} h
                        </TableCell>
                        <TableCell>
                          {log.schoolday ? (
                            <SchoolIcon color="primary" />
                          ) : (
                            <Typography variant="body2">Nein</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={() => handleDelete(log.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              {totalPages > 1 && (
                <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                  <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} color="primary" />
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default App;
